.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.section-description {
  margin-top: 5px;
  margin-left: 10px;
  text-align: justify;
  margin-right: 20px;
  font-family: "Google Sans Regular", sans-serif;
  font-size: 1.5rem;
  line-height: 2.3rem;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.center-div {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.padding-5 {
  margin: 25px;
}
.App-link {
  color: #09d3ac;
}

.heading {
  color: black;
}

.subTitle {
  color: #868e96;
  font-size: 20px;
}

/* Animations */
@keyframes fade_down {
  0% {
    opacity: 0.01;
    transform: translateY(-20px);
    transition: opacity 300ms cubic-bezier(0.645, 0.045, 0.355, 1),
      transform 300ms cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
    transition: opacity 300ms cubic-bezier(0.645, 0.045, 0.355, 1),
      transform 300ms cubic-bezier(0.645, 0.045, 0.355, 1);
  }
}

.center-horizontal {
  margin: 0 auto;
}

@keyframes fade_up {
  0% {
    opacity: 0.01;
    transform: translateY(20px);
    transition: opacity 300ms cubic-bezier(0.645, 0.045, 0.355, 1),
      transform 300ms cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
    transition: opacity 300ms cubic-bezier(0.645, 0.045, 0.355, 1),
      transform 300ms cubic-bezier(0.645, 0.045, 0.355, 1);
  }
}
