.main {
  width: 90%;
  padding: 20px 10px;
  margin: 0 auto;
  margin-top: 4rem;
}
.greet-main {
  width: 90%;
  padding: 20px 10px;
  margin: 0 auto;
  margin-top: 2rem;
}

.gretting-main {
  width: 90%;
  padding: 20px 10px;
  margin: 0 auto;
}

.greeting-main {
  display: flex;
  align-items: center;
}

.greeting-main > * {
  flex: 1;
  margin-bottom: 30px;
}
.button-greeting-div {
  display: flex;
  margin-top: 20px;
}

.greeting-text {
  margin-top: 80px;
  font-size: 70px;
  line-height: 1.1;
  font-family: "Google Sans Bold", sans-serif;
}

.greeting-nickname {
  font-size: 30px;
  line-height: 0;
  font-family: "Google Sans Bold Italic", sans-serif;
}

.logo-name {
  font-family: "Consolas", sans-serif;
  font-size: 3em;
  font-weight: bold;
  padding: 0 10px;
  /* color: #55198b; */
}
.greeting-text-p {
  font-size: 1.4rem;
  line-height: 2.5rem;
  margin-right: 40px;
  font-family: "Google Sans Medium", serif;
}

.greeting-image-div > * {
  max-width: 100%;
  height: auto;
}

/* Media Query */
@media (max-width: 1380px) {
  .greeting-text {
    font-size: 3em; /* or another unit like rem, %, etc. */
  }
  .greeting-text-p {
    font-size: 1.2em; /* or another unit like rem, %, etc. */
  }
}
@media (max-width: 768px) {
  .button-greeting-div {
    justify-content: space-around;
  }
  .greeting-text {
    font-size: 30px;
    margin-top: 0;
    text-align: center;
  }
  .greeting-nickname {
    font-size: 25px;
    text-align: center;
  }
  .greeting-text-p {
    font-size: 16px;
    margin-right: 0;
    line-height: normal;
    text-align: center;
  }
  .greeting-main {
    display: block;
  }

  .portfolio-repo-btn-div {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}

@media screen and (max-width: 768px) {
  .logo-name {
    font-size: 2.3em;
    padding: 0;
  }
  .gretting-main {
    flex-direction: column;
  }
  .greeting-image-div {
    height: 70%;
  }
}

@media screen and (max-width: 480px) and (orientation: landscape) {
  .center-text {
    display: flex;
    justify-content: center; /* Horizontally center */
    align-items: center; /* Vertically center */
    height: 100vh; /* Optional: Set height for vertical centering */
  }
}
